import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import TextImage from "../components/textImage"
import { graphql, useStaticQuery } from "gatsby"
import Video from "../components/video"
import HeroImage from "../components/heroImage"

const Presse = () => {
  const data = useStaticQuery(graphql`
    query {
      index1: file(relativePath: { eq: "presse-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index2: file(relativePath: { eq: "presse-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index3: file(relativePath: { eq: "presse-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index4: file(relativePath: { eq: "presse-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index5: file(relativePath: { eq: "presse-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index6: file(relativePath: { eq: "presse-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index7: file(relativePath: { eq: "presse-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout page={"presse"}>
      <SEO title="Presse"/>
      <TextImage source_position={"top"}
                 headline={"Presse"}
                 source={data.index1.childImageSharp.fluid}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 l-11 l-offset-1">

          </div>
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Schön zu sehen, dass es euch interessiert, was den Unterschied für uns macht. Nicht nur darum, sondern
              weil es uns viel Freude bereitet darüber zu berichten gibt es nun schon einige Artikel und TV-Beiträge aus
              den letzten Jahren. Eine kleine Auswahl könnt ihr euch hier noch einmal anschauen.
            </p>
          </div>
        </div>
      </TextImage>
      <div className={"wrapper"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 m-12">
            <Video type={"internal"} text_padding={false} text_position={"bottom"}
                   videoImage={data.index2.childImageSharp.fluid}
                   videoFile={"Auszeit"}
                   videoSize={"small"}
                   text={"Video (ab 5:30 min) <br/><span class='h2'>Auszeit in Bonn</span>"}/>
          </div>
          <div data-srgrid-col="xs-24 m-12">
            <a className={"link"}
               href={"https://www1.wdr.de/mediathek/video/sendungen/quarks-und-co/video-wie-backen-traditionelle-baecker--und-wie-backt-die-industrie-100.html"}>
              <Video type={"none"} text_padding={false} text_position={"bottom"}
                     videoImage={data.index3.childImageSharp.fluid}
                     videoSize={"small"}
                     text={"Video<br/><span class='h2'>Wie backen traditionelle Bäcker – und wie backt die Industrie?</span>"}/>
            </a>
          </div>
        </div>
      </div>
      <Video type={"internal"} text_position={"bottom"} videoFile={"Lokalzeit"}
             text={"Video ab (3:33min) <br/> <span class='h2'>Lokalzeit</span>"}
             videoImage={data.index7.childImageSharp.fluid}/>

      <div className={"wrapper"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 m-8">
            <a className={"link"}
               href={"https://www.express.de/bonn/kultladen-in-bonner-suedstadt-darum-steht-baecker-max-nur-auf-brot-29295840"}>
              <HeroImage source={data.index4.childImageSharp.fluid}
                         text={"Artikel <br /> <span class='h2'>Kultladen in der Bonner Südstadt</span>"}
                         text_padding={false}
                         text_position={"bottom-left"}/>
            </a>
          </div>
          <div data-srgrid-col="xs-24 m-8">
            <a href={"https://www.rheinexklusiv.de/baeckerei-max-kugel-liebt-brot/"} className={"link"}>
              <HeroImage source={data.index5.childImageSharp.fluid}
                         text={"Artikel <br /> <span class='h2'>Da Wo's nur Brot gibt</span>"}
                         text_padding={false}
                         text_position={"bottom-left"}/>
            </a>
          </div>
          <div data-srgrid-col="xs-24 m-8">
            <a href={"https://www.pikebrothers.com/max-kugel-liebt-brot/"} className={"link"}>
              <HeroImage source={data.index6.childImageSharp.fluid}
                         text={"Interview <br /> <span class='h2'>Max Kugel über seinen Weg zum Bäckerhandwerk</span>"}
                         text_padding={false}
                         text_position={"bottom-left"}/>
            </a>
          </div>
        </div>
      </div>
      <div className={"wrapper"}>
        <Video type={"youtube"} text_position={"bottom"} text={"Video <br/> <span class='h2'>BE.INSIDE</span>"}
               text_padding={false}/>
      </div>
    </Layout>
  )
}

export default Presse